import { ActionFunctionArgs, LoaderFunctionArgs, redirect } from "react-router";
import { Form, Link, useActionData, useNavigation } from "react-router";

import { login } from "./queries";
import { getAuthFromRequest, setAuthOnResponse } from "~/services/auth.server";
import { ROUTES } from "~/services/const";
import { validateEmail, validateMultiple, validatePassword } from "~/services/validator.server";
import AuthTitle from "../components/Title";
import AuthContainer from "../components/Container";
import { Button } from "~/components/ui/button";

interface ActionData {
  ok?: boolean;
  errors?: {
    _global?: string;
    email?: string;
    password?: string;
  };
}

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getAuthFromRequest(request);

  if (!!session) {
    return redirect(ROUTES.HOME);
  }

  return null;
}

export async function action({ request }: ActionFunctionArgs): Promise<ActionData> {
  const formData = await request.formData();

  const email = String(formData.get("email") || "");
  const password = String(formData.get("password") || "");

  const errors = validateMultiple({
    email: await validateEmail(email),
    password: await validatePassword(password),
  });

  if (errors) {
    return { ok: false, errors };
  }

  const userDetails = await login(email, password);

  if (!userDetails) {
    console.error("Unable to login", email);
    const globalErrorMessage = "Não foi possível iniciar sessão. Verifica o teu email e password e tenta novamente.";
    return { ok: false, errors: { _global: globalErrorMessage } };
  }

  return setAuthOnResponse(redirect(ROUTES.DASHBOARD), userDetails);
}

export default function LoginRoute() {
  let actionResult = useActionData<typeof action>();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  return (
    <>
      <AuthTitle>Bem-vindo de volta 👋</AuthTitle>

      <AuthContainer>
        <Form className="" method="post">
          <div className="mb-6">
            <h3 className="text-gray-700 font-semibold text-xl">Iniciar Sessão</h3>
          </div>
          {actionResult?.errors?._global && <p className="text-red-700">{actionResult.errors._global}</p>}
          <div className="mb-6">
            <label className="block text-sm font-medium leading-6 text-gray-900 mb-2" htmlFor="email">
              Email
              {actionResult?.errors?.email && (
                <span id="email-error" className="text-brand-red">
                  {actionResult.errors.email}
                </span>
              )}
            </label>
            <input
              className="mb-4 form-input block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-blue leading-6"
              autoFocus
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              aria-describedby={actionResult?.errors?.email ? "email-error" : "login-header"}
              required
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium leading-6 text-gray-900 mb-2" htmlFor="password">
              Password{" "}
              {actionResult?.errors?.password && (
                <span id="password-error" className="text-brand-red">
                  {actionResult.errors.password}
                </span>
              )}
            </label>
            <input
              className="mb-4 form-input block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-blue leading-6"
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              aria-describedby="password-error"
              required
            />
          </div>

          <div className="mb-6">
            <Button className="w-full" type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Aguarde..." : "Iniciar Sessão"}
            </Button>
          </div>

          <div className="text-sm text-gray-700 mb-2">
            Não tem uma conta?{" "}
            <Link className="underline" to={ROUTES.SIGNUP}>
              Clica aqui para criar
            </Link>
            .
          </div>
          <div className="text-sm text-gray-700">
            Esqueceste a tua password?{" "}
            <Link className="underline" to={ROUTES.PASSWORD_RECOVER}>
              Recuperar
            </Link>
            .
          </div>
        </Form>
      </AuthContainer>
    </>
  );
}
